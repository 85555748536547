import React, {FC} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";

interface SearchInputProps {
  placeholder?: string
  value: string
  onChange: (newValue: string) => void
  fullWidth?: true
  focus?: true
}

export const SearchInput: FC<SearchInputProps> = props => {
  return <label className={`flex flex-row items-center text-sm font-medium text-brand-900 w-full ${!props.fullWidth && 'max-w-md'} border-2 border-slate-200 focus-within:border-brand-700 rounded px-2 h-10 bg-white`}>
    <FontAwesomeIcon icon={fa.faMagnifyingGlass} /><span className={"sr-only"}>Search</span>
    <input
      type={"search"}
      className={"outline-none text-base font-normal ml-2 w-full"}
      placeholder={"Search..."}
      value={props.value}
      autoFocus={props.focus}
      onChange={(e) => props.onChange(e.target.value)}
    />
  </label>
}