import React, {FC, ReactNode} from "react";

const widthStyles = {
  "sm": "w-full max-w-screen-sm",
  "md": "w-full max-w-screen-lg",
  "lg": "w-full max-w-screen-xl",
}

interface ContentContainerProps {
  children: ReactNode
  size?: keyof typeof widthStyles // defaults to MD
}

export const ContentContainer: FC<ContentContainerProps> = props => {
  const maxWidth = widthStyles[props.size ?? "md"]
  return <div className={`${maxWidth} mx-auto my-8`}>
    {props.children}
  </div>
}
