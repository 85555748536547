import {ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {ContentContainer} from "../components/content/ContentContainer";
import {PageHeader} from "../components/content/PageHeader";
import {useApi} from "../api/APIContext";
import {Loading} from "../components/Loading";
import {SearchPaginateReload} from "../components/data/SearchAndPaginate";
import {Company, Exam, Project, ProposedChange} from "../api/dto";
import {DataTable} from "../components/data/DataTable";
import {PillSelect} from "../components/form/PillSelect";
import {useNavigate} from "react-router-dom";
import {Callout} from "../components/content/Callout";
import {
  faCheck,
  faDownload,
  faExplosion,
  faFileImport,
  faInfo,
  faInfoCircle,
  faUpload
} from "@fortawesome/free-solid-svg-icons";
import {SectionHeader} from "../components/content/SectionHeader";
import {Paragraph} from "../components/Paragraph";
import { Button } from '../components/form/Button';
import {useApiCall, ValidationError} from "../api/api";
import {SectionDivider} from "../components/content/SectionDivider";
import {Input} from "../components/form/Input";
import {Simulate} from "react-dom/test-utils";
import input = Simulate.input;
import {useRefresh} from "../components/RefreshController";

export const ImportPage: FC<{}> = props => {
  const navigate = useNavigate()
  const {getExportConceptTemplateUrl, getExportEmptyTemplateUrl}  = useApiCall()

  async function downloadEmptyTemplate() {
    const url = getExportEmptyTemplateUrl()
    window.open(url, '_blank')
  }
  async function downloadConceptTemplate() {
    const url = getExportConceptTemplateUrl()
    window.open(url, '_blank')
  }

  return <ContentContainer size={'md'}>
    <PageHeader>Exporteren en Importeren</PageHeader>
    <Callout color={'primary'}>
      <h2 className={'text-brand-900 font-medium'}>Hoe werkt het?</h2>
      <p className={"text-brand-900 mb-0 my-3 text-sm"}>
        Je kunt hier voor-ingevulde CSV's downloaden met relevante data om aan te vullen of te wijzigen. Wanneer je een
        CSV hebt aangepast of aangevuld, kun je deze hier weer uploaden om de wijzigingen toe te passen.
      </p>
      <p className={"text-brand-900 mb-0 my-3 text-sm"}>
        Wanneer de naam van een project, bedrijf, of examen niet bekend is in het systeem, zal deze automatisch worden
        aangemaakt. Wanneer de naam wel bekend is (niet hoofdlettergevoelig), zal de data worden aangevuld of gewijzigd.
      </p>
      <p className={"text-brand-900 mb-0 my-3 text-sm"}>
        Je hebt de optie om een CSV te downloaden waar ook alle "concept" examens in staan, of een lege CSV. Wanneer je
        een CSV upload waar gewijzigde examens in staan die reeds afgerond zijn, zullen deze examens niet worden
        aangepast.
      </p>
      <div className={"mt-3 flex items-center space-x-3"}>
        <Button type={'primary'} size={'xs'} icon={faDownload} text={'template_leeg.csv'} onClick={downloadEmptyTemplate} />
        <Button type={'primary'} size={'xs'} icon={faDownload} text={'template_concepts.csv'} onClick={downloadConceptTemplate} />
      </div>
    </Callout>
    <ImportForm />
  </ContentContainer>
}

const ImportForm: FC<{}> = props => {
  const {importCsvTest, importCsvApply} = useApiCall()
  const refresh = useRefresh()
  const navigate = useNavigate()
  const fileInput = useRef<HTMLInputElement>(null)
  const [importContent, setImportContent] = useState<string | null>(null)
  const [errors, setErrors] = useState<{[key: string]: string[]}>({})
  const [changes, setChanges] = useState<ProposedChange[]>([])
  const [state, setState] = useState<'needs_validation'|'validated_success_changes'|'validated_success_no_changes'|'validated_failure'>('needs_validation')
  const onFileChange = useCallback((file: File) => {
    const reader = new FileReader()
    reader.onload = () => {
      const data = reader.result as string
      setImportContent(data)
    }
    reader.readAsText(file)
  }, [])

  useEffect(() => {
    const listener = (event: Event) => {
      const target = event.target as HTMLInputElement
      if (target.files && target.files.length > 0) {
        const file = target.files[0]
        onFileChange(file)
      }
    }
    fileInput.current?.addEventListener('change', listener)
    return () => {
      fileInput.current?.removeEventListener('change', listener)
    }
  }, [fileInput.current, onFileChange])

  const validateImport = async () => {
    setErrors({})
    setChanges([])
    setState('needs_validation')
    const changes = await importCsvTest(importContent!)
      .then((changes) => {
        if (changes.length > 0) {
          setState('validated_success_changes')
        } else {
          setState('validated_success_no_changes')
        }
        return changes
      })
      .catch((e) => {
        if (e instanceof ValidationError) {
          setErrors(e.errors)
          setState('validated_failure')
        }
        return [] as ProposedChange[]
      })
    setChanges(changes)
  }

  return <div className={""}>
    <PageHeader>Importeren</PageHeader>
    <section>
      <SectionDivider />
      <SectionHeader>1. Upload aangevulde/gewijzigde template</SectionHeader>
      <input type={'file'} className={"hidden"} ref={fileInput} />
      <Button type={'primary'} icon={faUpload} text={'Upload CSV'} onClick={() => fileInput.current?.click()} size={'sm'} />
    </section>
    {importContent && <section>
      <SectionDivider />
      <SectionHeader>2. Bekijk het geselecteerde bestand</SectionHeader>
      <pre className={"max-w-full overflow-y-scroll overflow-x-scroll h-32 text-xs bg-white rounded px-2 py-1 mb-3 border border-brand-600"}>{importContent}</pre>
      <Button type={'primary'} size={'sm'} icon={faCheck} text={'Valideren'} onClick={validateImport} />
      {(errors['content']??[]).length > 0 && <Callout color={'danger'} icon={faExplosion}>
        <h2 className={'text-red-800 font-medium'}>Fouten gevonden</h2>
        <ul className={'list-disc list-inside'}>
          {(errors['content']??[]).map((value, i) => {
            return <li key={i} className={'text-red-800'}>{value}</li>
          })}
        </ul>
      </Callout>}
    </section>}
    {state === 'validated_success_changes' && <section>
      <SectionDivider />
      <SectionHeader>3. Wijzigingen bekijken</SectionHeader>
      <DataTable data={changes} columns={[
        {header: 'Actie', property: 'action'},
        {header: 'Onderwerp', property: 'idName'},
        {header: 'Oude waarde', property: 'oldValue'},
        {header: 'Nieuwe waarde', property: 'newValue'},
      ]}  keyProperty={'idName'}  placeholder={<></>} />
      <div className={"mt-3"}>
        <Button type={'primary'} size={'sm'} icon={faCheck} text={'Toepassen'} onClick={() => {
          importCsvApply(importContent!)
            .then(() => {
              refresh()
              navigate('/')
            })
        }} />
      </div>
    </section>}
    {state === 'validated_success_no_changes' && <section>
      <SectionDivider />
      <SectionHeader>3. Wijzigingen bekijken</SectionHeader>
      <Callout color={'success'} icon={faInfo}>
        Er zijn geen wijzigingen gevonden in de geüploade CSV. Er zijn geen acties nodig.
      </Callout>
    </section>}
  </div>
}
