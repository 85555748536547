import { FC } from 'react';
import {ContentContainer} from "../components/content/ContentContainer";
import {PageHeader} from "../components/content/PageHeader";
import {DataTable} from "../components/data/DataTable";
import {useApi} from "../api/APIContext";
import {Loading} from "../components/Loading";
import {faEdit, faTimes, faToggleOff, faToggleOn} from "@fortawesome/free-solid-svg-icons";
import {useApiCall} from "../api/api";
import {useRefresh} from "../components/RefreshController";

export const AssessorsPage: FC<{}> = props => {
  const {assessors, loading, refresh } = useApi()
  const {updateAssessor, destroyAssessor} = useApiCall()
  return <ContentContainer size={'md'}>
    <PageHeader>Assessors</PageHeader>

    <Loading loading={loading}>
      <DataTable
        keyProperty={'id'}
        data={assessors}
        columns={[
          {header: "Name", property: 'last_name', transform: (value, row) => `${row.first_name} ${value}`},
          {header: "Enabled", property: 'options', transform: (_, r) => r.isEnabled() ? 'Yes' : 'No'},
        ]}
        onClickActions={[
          {
            icon: (r) =>  r.isEnabled() ? faToggleOn : faToggleOff,
            text: (r) => r.isEnabled() ? 'Disable' : 'Enable',
            onClick: async (row) => {
              await updateAssessor(row, {options: {enabled: !row.isEnabled()}})
              await refresh.assessors(true)
            }
          },
          {
            icon: faTimes,
            text: 'Verwijderen',
            variant: 'danger',
            onClick: async (row) => {
              const sure = confirm('Weet je zeker dat je deze assessor wilt verwijderen?')
              if (sure) {
              await destroyAssessor(row.id).then(() => console.log('lksajdflkjsdflkj'))
                await refresh.assessors(true)
              }
            }
          }
        ]}
        placeholder={'Geen vragenlijsten gevonden'}
      />
    </Loading>
  </ContentContainer>
}
