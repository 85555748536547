import { FC } from 'react';
import {ContentContainer} from "../components/content/ContentContainer";
import {PageHeader} from "../components/content/PageHeader";
import {DataTable} from "../components/data/DataTable";
import {useApi} from "../api/APIContext";
import {Loading} from "../components/Loading";

export const QuestionSetsPage: FC<{}> = props => {
  const {questionSets, loading} = useApi()
  return <ContentContainer size={'md'}>
    <PageHeader>Vragenlijsten</PageHeader>

    <Loading loading={loading}>
      <DataTable
        keyProperty={'id'}
        data={questionSets}
        columns={[
          {header: "Name", property: 'name'},
          {header: "Score categorieën", property: 'scoringPerCategory', transform: (value) => Object.keys(value).join(', ')},
          {header: "Aantal vragen", property: 'questions', transform: (value) => value.length},
        ]}
        placeholder={'Geen vragenlijsten gevonden'}
      />
    </Loading>
  </ContentContainer>
}
