import React, {FC, Fragment, useMemo, useState} from 'react';
import {ContentContainer} from "../components/content/ContentContainer";
import {PageHeader} from "../components/content/PageHeader";
import {useApi, useFetchedResource} from "../api/APIContext";
import {Company, Exam, ExamParticipant, Project, QuestionSet} from "../api/dto";
import {useNavigate, useParams} from "react-router-dom";
import {Loading} from "../components/Loading";
import {useApiCall} from "../api/api";
import {useRefreshEffect} from "../components/RefreshController";
import {SectionHeader} from "../components/content/SectionHeader";
import {DataTable} from "../components/data/DataTable";
import {SectionDivider} from "../components/content/SectionDivider";
import {Card} from "../components/Card";
import {
  faArchive, faBackward,
  faCheckCircle, faCircleExclamation,
  faClock,
  faDownload,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "../components/form/Button";

export const ExamDetailsPage: FC<{}> = () => {
  const {examId} = useParams()
  const {exams, projects, companies, questionSets, loading, refreshAll} = useApi()
  const {archiveExam, destroyExam, revertExam} = useApiCall()
  const navigate = useNavigate()
  const enrichedExam = useMemo(() => {
    const projectsMap = projects.reduce((map, project) => {
      map[project.id] = project
      return map
    }, {} as {[key: string]: Project})
    const companiesMap = companies.reduce((map, company) => {
      map[company.id] = company
      return map
    }, {} as {[key: string]: Company})
    const questionSetsMap = questionSets.reduce((map, questionSet) => {
      map[questionSet.id] = questionSet
      return map
    }, {} as {[key: string]: QuestionSet})
    const exam = exams.find(exam => exam.id === examId)
    if (!exam) return null
    const project = projectsMap[exam.projectId]
    const company = companiesMap[project?.companyId ?? '']
    const questionSet = questionSetsMap[exam.questionSetId]
    return {
      ...exam,
      projectName: project?.name ?? 'Onbekend project',
      project,
      companyName: company?.name ?? 'Onbekend bedrijf',
      company,
      questionSetName: questionSet?.name ?? 'Onbekende vragenlijst',
      questionSet,
    }

  }, [exams, projects, companies, questionSets, examId])

  const deleteExam = async () => {
    if (!examId) return
    if (!window.confirm('Weet je zeker dat je dit examen wilt archiveren?')) return
    await archiveExam(examId)
    refreshAll()
    navigate('/')
  }

  const permanentDeleteExam = async () => {
    if (!examId) return
    if (!window.confirm('Weet je zeker dat je dit examen permanent wilt verwijderen? \n Deze actie valt niet terug te draaien')) return
    await destroyExam(examId)
    refreshAll()
    navigate('/')
  }

  const revertArchivedExam = async () => {
    if (!examId) return
    if (!window.confirm('Weet je zeker dat je dit examen terug wilt zetten?')) return
    await revertExam(examId)
    refreshAll()
    navigate('/')
  }

  return <ContentContainer size={'md'}>
    <Loading loading={loading || !enrichedExam}>
      {enrichedExam && <div>
        <div className={"flex justify-between items-center"}>
          <PageHeader>Assessment {enrichedExam.name??''}</PageHeader>
          <div className={"h-12 mb-6 flex items-center"}>
            {enrichedExam.archivedAt ? <div className={'flex'}>
              <Button type={'secondary'} size={'md'} text={'Examen terugzetten'} icon={faBackward} onClick={revertArchivedExam} />
                <Button type={'danger'} size={'md'} text={'Examen permanent Verwijderen'} icon={faCircleExclamation} onClick={permanentDeleteExam} />
            </div> :
            <Button type={'danger'} size={'md'} text={'Examen archiveren'} icon={faArchive} onClick={deleteExam} />}
          </div>
        </div>
        <dl className={"flex items-center"}>
          <dt
            className={"rounded-l border-t border-l border-b border-brand-200 bg-brand-50 text-brand-600 px-2 font-medium"}>Project
          </dt>
          <dd className={"rounded-r border border-brand-200 px-2 mr-3"}>{enrichedExam.projectName}</dd>
          <dt
            className={"rounded-l border-t border-l border-b border-brand-200 bg-brand-50 text-brand-600 px-2 font-medium"}>Bedrijf
          </dt>
          <dd className={"rounded-r border border-brand-200 px-2 mr-3"}>{enrichedExam.companyName}</dd>
          <dt className={"rounded-l border-t border-l border-b border-brand-200 bg-brand-50 text-brand-600 px-2 font-medium"}>Vragenlijst
          </dt>
          <dd className={"rounded-r border border-brand-200 px-2 mr-3"}>{enrichedExam.questionSetName}</dd>
        </dl>
        <ExamResultsOverview exam={enrichedExam}/>
        <ExamParticipantsOverview exam={enrichedExam}/>
      </div>}
    </Loading>
  </ContentContainer>
}

const ExamResultsOverview: FC<{ exam: Exam }> = props => {
  const {getExamExportUrl,getExamReportUrl} = useApiCall()
  const [override, setOverride] = useState(false)
  const {listExamParticipants} = useApiCall()
  const participants = useFetchedResource<ExamParticipant[], Exam>((exam: Exam) => listExamParticipants(exam.id))
  useRefreshEffect(() => {
    participants.reload(props.exam)
  }, [props.exam])
  return <div>
    <SectionDivider />
    <SectionHeader>Resultaten</SectionHeader>
    <Card title={'Resultaten'}>
      {(props.exam.status === 'Afgerond' || override) && <div className={'flex flex-col items-center space-y-3 py-4'}>
        <FontAwesomeIcon icon={faCheckCircle} className={'text-brand-900'} />
        <h2 className={"font-lg font-semibold text-brand-800"}>Finished</h2>
        <div className={"w-full"}>
          <div className={"grid grid-cols-2 gap-4"}>
            <div className={""}>
              <h3 className={"font-bold"}>Ruwe CSV export</h3>
              <p className={"text-slate-800"}>CSV met de structuur van de Excel rekensheets.</p>
            </div>
            <div className={"flex items-center"}>
              <Button size={'md'} type={'secondary'} icon={faDownload} text={'Download Raw Export CSV'} onClick={() => {
                window.open(getExamExportUrl(props.exam.id, true), '_blank')
              }}/>
            </div>
            <div className={""}>
              <h3 className={"font-bold"}>Test Report CSV</h3>
              <p className={"text-slate-800"}>Data uit Test Report, in CSV formaat voor de verwerking door klanten.</p>
            </div>
            <div className={"flex items-center"}>
              <Button size={'md'} type={'secondary'} icon={faDownload} text={'Download Test Report CSV'}
                      onClick={() => {
                        window.open(getExamExportUrl(props.exam.id, false), '_blank')
                      }}/>
            </div>
            <div className={""}>
              <h3 className={"font-bold"}>Test report PDF</h3>
              <p className={"text-slate-800"}>Opgemaakt testreport inclusief scorekaarten van alle contractors.</p>
            </div>
            <div className={"flex items-center"}>
              <Button size={'md'} type={'secondary'} icon={faDownload} text={'Download Test Report PDF'}
                      onClick={() => {
                        window.open(getExamReportUrl(props.exam.id), '_blank')
                      }}/>
            </div>
            <div className={""}>
              <h3 className={"font-bold"}>Contractor Test Report</h3>
              <p className={"text-slate-800"}>Opgemaakt testreport inclusief scorekaarten van een specifieke contractor.</p>
            </div>
            <div className={"flex flex-col items-start"}>
              {Array.from(new Set(participants.resource?.map(p => p.contractor)??[])).map((contractor, i) => {
                return <Fragment key={i}>
                  <Button size={'md'} type={'secondary'} icon={faDownload} text={`Download Test Report voor ${contractor}`}
                     onClick={() => {
                       window.open(getExamReportUrl(props.exam.id, contractor), '_blank')
                     }}/>
                </Fragment>
              })}
            </div>

          </div>
        </div>
      </div>}
      {props.exam.status === 'In uitvoering' && !override &&
        <div className={'flex flex-col items-center space-y-3 py-4'}>
          <FontAwesomeIcon icon={faClock} className={'text-brand-900'}/>
          <h2 className={"font-lg font-semibold text-brand-800"}>In progress</h2>
          <p className={"text-sm text-brand-700"}>Dit examen is nog niet afgerond, pas dan kun je de resultaten
            exporteren</p>
          <button className={"text-sm underline text-red-700"} onClick={() => setOverride(true)}>Toch nu exporteren</button>
      </div>}
      {props.exam.status === 'Concept' && !override && <div className={'flex flex-col items-center space-y-3 py-4'}>
        <FontAwesomeIcon icon={faPencil} className={'text-brand-900'}/>
        <h2 className={"font-lg font-semibold text-brand-800"}>Concept</h2>
        <p className={"text-sm text-brand-700"}>Dit examen is nog niet afgerond, pas dan kun je de resultaten
          exporteren</p>
        <button className={"text-sm underline text-red-700"} onClick={() => setOverride(true)}>Toch nu exporteren</button>
      </div>}
    </Card>
  </div>

}

const ExamParticipantsOverview: FC<{ exam: Exam }> = props => {
  const {listExamParticipants} = useApiCall()
  const participants = useFetchedResource<ExamParticipant[], Exam>((exam: Exam) => listExamParticipants(exam.id))
  useRefreshEffect(() => {
    participants.reload(props.exam)
  }, [props.exam])
  return <div>
    <SectionDivider />
    <SectionHeader>Deelnemers</SectionHeader>
    <Loading loading={participants.loading}>
      <DataTable<ExamParticipant> keyProperty={'id'} data={(participants.resource ?? []) as ExamParticipant[]} columns={[
        {header: 'Position', property: 'position'},
        {header: 'Naam', property: 'name'},
        {header: 'Contractor', property: 'contractor'},
        {header: 'Subcontractor', property: 'subContractor'},
        {header: 'Badge', property: 'badgeNumber'},
        {header: 'Certification', property: 'certification'},
      ]} placeholder={<>Nog geen deelnemers</>} />
    </Loading>
  </div>
}
