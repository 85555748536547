import React, {useEffect, useMemo, useState} from "react";
import {SearchInput} from "./SearchInput";
import * as fa from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {usePersistentState} from "../../util/usePersistentState";
import {Select} from "../form/Select";
import {IconButton} from "../form/Button";

interface SearchAndPaginateProps<T> {
  data: T[]
  filterProperties: (keyof T)[]
  resultsBuilder: (results: T[]) => JSX.Element
  onReload?: () => void
  paginationEnabled?: boolean
}

const PAGINATION_BUTTON_LIMIT = 3
const PAGINATION_LIMIT_DEFAULT = 25
const PAGINATION_LIMIT_OPTIONS = [10, 25, 50, 100]

export function SearchPaginateReload<T>(props: SearchAndPaginateProps<T>): JSX.Element {
  const [search, setSearch] = React.useState("")
  const filteredData = React.useMemo(() => {
    if (search === "") {
      return props.data
    }
    return props.data.filter(item => {
      const values = props.filterProperties?.map((key) => {
        return item[key]
      }) ?? []
      return values.some(v => JSON.stringify(v).toLowerCase().includes(search.toLowerCase()))
    })
  }, [props.data, props.filterProperties, search])

  const paginationEnabled = useMemo(() => {
    return !! props.paginationEnabled
  }, [props.paginationEnabled])

  const [paginationLimit, setPaginationLimit] = usePersistentState<number>("paginationLimit", PAGINATION_LIMIT_DEFAULT)

  const [page, setPage] = useState(0)
  const pageCount = useMemo(() => {
    return Math.ceil(filteredData.length / paginationLimit)
  }, [paginationLimit, filteredData])
  useEffect(() => {
    if (page >= pageCount && pageCount > 0) {
      setPage(pageCount - 1)
    } else if (page < 0) {
      setPage(0)
    }
  }, [page, pageCount]);
  const filteredAndPaginatedData = useMemo(() => {
    if (!paginationEnabled) {
      return filteredData
    }
    return filteredData.slice(page * paginationLimit, (page + 1) * paginationLimit)
  }, [page, paginationEnabled, filteredData, paginationLimit])
  const pages = useMemo(() => {
    const pages = []
    for (let i= 0; i < pageCount; i++) {
      pages.push(i)
    }
    if (pageCount <= PAGINATION_BUTTON_LIMIT) {
      return pages
    }
    // TODO: When a list is very long, only render a set number of buttons based on PAGINATION_BUTTON_LIMIT
    return pages
  }, [pageCount, page])

  const paginationButtonBaseStyle = 'rounded h-8 w-8 flex items-center justify-center font-medium'
  return <div>
    <div className={"flex items-end justify-between mb-4"}>
      <div className={"flex-1"}>
        <SearchInput value={search} focus onChange={setSearch} />
      </div>
      {paginationEnabled && <div className={'mr-2'}>
        <Select label={'Per page'} inline options={Object.fromEntries(PAGINATION_LIMIT_OPTIONS.map(i => [String(i),String(i)]))} value={String(paginationLimit)} onChange={(newLimit) => setPaginationLimit(Number(newLimit))} />
      </div>}
      {props.onReload ? <IconButton icon={fa.faRefresh} onClick={props.onReload} type={"secondary"} size={"sm"} /> : null}
    </div>
    <div>
      {props.resultsBuilder(filteredAndPaginatedData)}
    </div>
    {paginationEnabled && pageCount > 1 ? <div className={"flex"}>
      <div className={"flex items-center justify-start mt-4 border border-slate-200 rounded-lg text-brand-800 p-1 bg-white space-x-1"}>
        <button className={`${paginationButtonBaseStyle} ${page <= 0 ? 'text-slate-300' : 'hover:bg-brand-100 hover:text-brand-600'}`} disabled={page <= 0} onClick={() => setPage(p => p-1)}>
          <FontAwesomeIcon icon={fa.faChevronLeft} />
        </button>
        {pages.map((i) => {
          return <button className={`${paginationButtonBaseStyle} hover:bg-brand-100 hover:text-brand-600 ${page === i ? 'bg-brand-50 border border-brand-500' : ''}`} key={i} onClick={() => setPage(i)}>
            {i+1}
          </button>
        })}
        <button className={`${paginationButtonBaseStyle} ${(page+1) >= pageCount ? 'text-slate-300' : 'hover:bg-brand-100 hover:text-brand-600'}`} disabled={(page+1) >= pageCount} onClick={() => setPage(p => p+1)}>
          <FontAwesomeIcon icon={fa.faChevronRight} />
        </button>
      </div>
    </div> : null}
  </div>
}
