import {FC, useMemo, useState} from 'react';
import {ContentContainer} from "../components/content/ContentContainer";
import {PageHeader} from "../components/content/PageHeader";
import {useApi} from "../api/APIContext";
import {Loading} from "../components/Loading";
import {SearchPaginateReload} from "../components/data/SearchAndPaginate";
import {Company, Exam, Project} from "../api/dto";
import {DataTable} from "../components/data/DataTable";
import {PillSelect} from "../components/form/PillSelect";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDownAZ} from "@fortawesome/free-solid-svg-icons";
import {usePersistentState} from "../util/usePersistentState";


const sortOptions = {
  nameAsc: 'Naam A -> Z',
  nameDesc: 'Naam Z -> A',
  createdDesc: 'Nieuwste examens',
  createdAsc: 'Oudste examens',
  updatedAsc: 'Langst geen update',
  updatedDesc: 'Recent geüpdate',
}

export const OverviewPage: FC<{}> = props => {
  const navigate = useNavigate()
  const {exams, projects, companies, loading} = useApi()
  const enrichedExams = useMemo(() => {
    const projectsMap = projects.reduce((map, project) => {
      map[project.id] = project
      return map
    }, {} as {[key: string]: Project})
    const companiesMap = companies.reduce((map, company) => {
      map[company.id] = company
      return map
    }, {} as {[key: string]: Company})
    return exams.map(exam => {
      const project = projectsMap[exam.projectId]
      const company = companiesMap[project?.companyId ?? '']
      return {
        ...exam,
        projectName: project?.name ?? 'Onbekend project',
        project,
        companyName: company?.name ?? 'Onbekend bedrijf',
        company,
      }
    })

  }, [exams, projects, companies])
  const statusFilters = {
    'Alle': (exam: Exam) => exam.archivedAt === null,
    'Afgerond': (exam: Exam) => exam.status === 'Afgerond',
    'In uitvoering': (exam: Exam) => exam.status === 'In uitvoering',
    'Concept': (exam: Exam) => exam.status === 'Concept',
    'Gearchiveerd': (exam: Exam) => exam.status === 'Gearchiveerd',
  }
  const [selectedStatusFilter, setSelectedStatusFilter] = useState<keyof typeof statusFilters>('Alle')
  const [sort, setSort] = usePersistentState<keyof typeof sortOptions>(`exam-overview-sort`, 'nameDesc')

  const filteredExams = useMemo(() => {
    const filteredExams = enrichedExams.filter(statusFilters[selectedStatusFilter])
    return searchFilterSortExams(filteredExams, sort)
  }, [enrichedExams, statusFilters, selectedStatusFilter, sort])

  return <ContentContainer size={'md'}>
    <PageHeader>Overzicht</PageHeader>

    <SearchPaginateReload data={filteredExams} paginationEnabled={true} filterProperties={['name', 'projectId', 'companyId']} resultsBuilder={(results) => {
      return <div>
        <div className={"flex justify-between"}>
          <PillSelect options={Object.fromEntries(Object.keys(statusFilters).map(k => [k,k]))} value={selectedStatusFilter} onChange={(k) => setSelectedStatusFilter(k as keyof typeof statusFilters)} />
          <div className={"-mr-[1px] -my-[1px] space-x-2 rounded flex items-center py-1"}>
            <FontAwesomeIcon icon={faArrowDownAZ} className={"text-md font-medium text-brand-900"}/>
            <select value={sort} onChange={(e) => setSort(e.target.value as keyof typeof sortOptions)} className={"border-2 border-slate-200 outline-brand-700 text-black rounded text-base font-normal px-2 h-10 w-full"}>{Object.entries(sortOptions).map(([key, value]) => <option key={key} value={key}>{value}</option>)}</select>
          </div>
        </div>
        <DataTable data={results} keyProperty={'id'} loading={loading} placeholder={<>Geen examens in selectie</>} columns={[
          {header: "Naam", property: 'name'},
          {header: "Project", property: 'projectId', transform: (projectId) => projects.find(project => project.id === projectId)?.name},
          {header: "Bedrijf", property: 'companyId', transform: (companyId) => companies.find(company => company.id === companyId)?.name},
          {header: "Status", property: 'status'},
          {header: "Laatste wijziging", property: 'lastUpdated', transform: (value) => value.toLocaleString('nl')},
        ]} onClickRow={(exam) => {navigate(`/exams/${exam.id}`)}}/>
      </div>
    }} />
  </ContentContainer>
}

function searchFilterSortExams(exams: Exam[], sort: keyof typeof sortOptions): Exam[] {
  return exams.sort((a, b) => {
    if (sort === 'nameAsc') {
      if (!a.name) return 1;
      if (!b.name) return -1;
      return a.name.localeCompare(b.name);
    }
    if (sort === 'nameDesc') {
      if (!a.name) return 1;
      if (!b.name) return -1;
      return b.name.localeCompare(a.name);
    }
    if (sort === 'createdAsc') {
      return a.createdAt > b.createdAt ? 1 : -1;
    }
    if (sort === 'createdDesc') {
      return a.createdAt < b.createdAt ? 1 : -1;
    }
    if (sort === 'updatedAsc') {
      return a.updatedAt > b.updatedAt ? 1 : -1;
    }
    if (sort === 'updatedDesc') {
      return a.updatedAt < b.updatedAt ? 1 : -1;
    }
    return 0;
  });
}
