import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import {AuthMiddleware, AuthProvider} from "./auth/AuthProvider";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {APIContextProvider} from "./api/APIContext";
import {ModalProvider} from "./components/layout/ModalProvider";
import initSentry from "./sentry";
import {OverviewPage} from "./pages/OverviewPage";
import {generateDynamicColorSchemeCSS} from "./util/colorScheme";
import colors from "tailwindcss/colors";
import {QuestionSetsPage} from "./pages/QuestionSetsPage";
import {ExamDetailsPage} from "./pages/ExamDetailsPage";
import {ImportPage} from "./pages/ImportPage";
import {RefreshController} from "./components/RefreshController";
import {AssessorsPage} from "./pages/AssessorsPage";

initSentry()

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <AuthProvider>
    <React.StrictMode>
      <style>{generateDynamicColorSchemeCSS(colors.cyan)}</style>
      <BrowserRouter>
        <RefreshController>
          <AuthMiddleware>
            <APIContextProvider>
              <ModalProvider>
                <Routes>
                  <Route path={"/"} element={<OverviewPage/>}/>
                  <Route path={"/exams/:examId"} element={<ExamDetailsPage />}/>
                  <Route path={"/question-sets"} element={<QuestionSetsPage />}/>
                  <Route path={"/export-import"} element={<ImportPage />}/>
                  <Route path={"/assessors"} element={<AssessorsPage />}/>
                </Routes>
              </ModalProvider>
            </APIContextProvider>
          </AuthMiddleware>
        </RefreshController>
      </BrowserRouter>
    </React.StrictMode>
  </AuthProvider>,
)
