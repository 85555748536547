export class Assessor {
  constructor(
    public readonly id: string,
    public readonly first_name: string,
    public readonly last_name: string,
    public readonly options: any,
    public readonly createdAt: Date,
    public readonly updatedAt: Date,
  ) {
  }

  isEnabled(): boolean {
    return this.options?.enabled !== 'false'
  }

  static fromJson(json: any): Assessor {
    return new Assessor(
      json['id'],
      json['first_name'],
      json['last_name'],
      json['options'] ? Array.isArray(json['options']) ? {} : json['options'] : {},
      new Date(json['created_at']),
      new Date(json['updated_at']),
    )
  }
}

export class Company {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly createdAt: Date,
    public readonly updatedAt: Date,
  ) {
  }

  static fromJson(json: any): Company {
    return new Company(
      json['id'],
      json['name'],
      new Date(json['created_at']),
      new Date(json['updated_at']),
    )
  }
}

export class QuestionSet {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly questions: any[],
    public readonly scoringPerCategory: {[category: string]: {classification: string, color: string, fromScore: number}[]},
    public readonly createdAt: Date,
    public readonly updatedAt: Date,
  ) {
  }

  static fromJson(json: any): QuestionSet {
    return new QuestionSet(
      json['id'],
      json['name'],
      json['questions'],
      json['default_category_scores']?.['scoring_per_score_category'] ?? {},
      new Date(json['created_at']),
      new Date(json['updated_at']),
    )
  }
}

export class Project {
  constructor(
    public readonly id: string,
    public readonly companyId: string,
    public readonly name: string,
    public readonly createdAt: Date,
    public readonly updatedAt: Date,
  ) {
  }

  static fromJson(json: any): Project {
    return new Project(
      json['id'],
      json['company_id'],
      json['name'],
      new Date(json['created_at']),
      new Date(json['updated_at']),
    )
  }
}

export class Exam {
  public readonly status: 'Concept'|'In uitvoering'|'Afgerond'|'Gearchiveerd'
  public readonly lastUpdated: Date
  constructor(
    public readonly id: string,
    public readonly projectId: string,
    public readonly companyId: string,
    public readonly questionSetId: string,
    public readonly name: string|null,
    public readonly startedAt: Date|null,
    public readonly finishedAt: Date|null,
    public readonly createdAt: Date,
    public readonly updatedAt: Date,
    public readonly archivedAt: Date|null,
  ) {
    if (this.archivedAt !== null) {
      this.status = 'Gearchiveerd'
    } else if (this.finishedAt !== null) {
      this.status = 'Afgerond'
    } else if (this.startedAt === null) {
      this.status = 'Concept'
    } else {
      this.status = 'In uitvoering'
    }

    this.lastUpdated = new Date(Math.max(this.createdAt.getTime(), this.updatedAt.getTime(), startedAt?.getTime() ?? 0, finishedAt?.getTime() ?? 0))
  }


  static fromJson(json: any): Exam {
    return new Exam(
      json['id'],
      json['project_id'],
      json['company_id'],
      json['question_set_id'],
      json['name'] ?? null,
      json['started_at'] ? new Date(json['started_at']) : null,
      json['finished_at'] ? new Date(json['finished_at']) : null,
      new Date(json['created_at']),
      new Date(json['updated_at']),
      json['archived_at'] ? new Date(json['archived_at']) : null,
    )
  }
}

export class ExamParticipant {
  constructor(
    public readonly id: string,
    public readonly examId: string,
    public readonly contractor: string,
    public readonly subContractor: string,
    public readonly name: string,
    public readonly position: number,
    public readonly badgeNumber: string|null,
    public readonly nationality: string|null,
    public readonly certification: string|null,
    public readonly createdAt: Date,
    public readonly updatedAt: Date,
  ) {
  }

  static fromJson(json: any): ExamParticipant {
    return new ExamParticipant(
      json['id'],
      json['exam_id'],
      json['contractor'],
      json['sub_contractor'],
      json['name'],
      json['position'],
      json['badge_number'] ?? null,
      json['nationality'] ?? null,
      json['certification'] ?? null,
      new Date(json['created_at']),
      new Date(json['updated_at']),
    )
  }
}

export class ProposedChange {
  constructor(
    public readonly action: string,
    public readonly idName: string,
    public readonly oldValue: string|null,
    public readonly newValue: string,
  ) {
  }

  static fromJson(json: any): ProposedChange {
    return new ProposedChange(
      json['action'],
      json['id_name'],
      json['old_value'],
      json['new_value'],
    )
  }
}
